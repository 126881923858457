import { showErrorNotification } from './notifications';
import { ESCAPE_KEY_CODE } from '../helpers/constants';
import { findElem, findAllElems } from '../helpers/domHelpers';
import { waitPromise } from '../helpers/waitPromise';

export function initProductFilters({
  itemsWrapperSelector,
  filterUrlPrefix,
  onInitFilters,
  onFilter,
}) {
  initColorsSection();

  const collectionItems = findElem(itemsWrapperSelector);

  const popupElem = findElem('.product-filters__popup');
  const popupContentElem = findElem('.product-filters__popup-content');
  const popupToggle = findElem('#productFiltersPopupToggle');
  const popupCloseBtn = findElem('#productFiltersPopupCloseBtn');
  const productFilterValueTemplateElem = findElem('#productFilterValueTemplate').content;
  const productFilterValueColorTemplateElem = findElem('#productFilterValueColorTemplate').content;

  const categoryElems = findAllElems('[data-product-filters-category]');
  const clearFiltersBtns = findAllElems('.product-filters__clear-btn');
  const categoryToggles = findAllElems('[data-product-filters-category-target]');
  const filtersValuesElem = findElem('#productFiltersValues');
  const filterValueElems = findAllElems('[data-product-filter-value]');
  const searchToggle = findElem('#productSearch');
  let selectedFilters = [];
  let lastSelectedFilters = null;

  searchToggle.addEventListener('click', () => searchByFilters(true));

  // Close popup on shadow click
  popupElem.addEventListener('click', (event) => {
    if (!popupContentElem.contains(event.target)) {
      closePopup(true);
    }
  });

  // Open popup on toggle click
  popupToggle.addEventListener('click', () => {
    popupElem.classList.add('active');
    document.addEventListener('keyup', closePopupOnClickEscape);

    document.body.classList.add('overflow-hidden');

    lastSelectedFilters = [...selectedFilters];
  });

  // Close popup on close click
  popupCloseBtn.addEventListener('click', () => closePopup(true));

  // Switch filter categories
  categoryToggles.forEach((categoryToogleElem) => {
    const categoryTarget = categoryToogleElem.getAttribute('data-product-filters-category-target');
    categoryToogleElem.addEventListener('click', () => {
      categoryToggles.forEach((elem) => elem.classList.remove('active'));
      categoryElems.forEach((elem) => elem.classList.add('hidden'));

      categoryToogleElem.classList.add('active');
      findElem(`[data-product-filters-category="${categoryTarget}"]`).classList.remove('hidden');
    });
  });

  filterValueElems.forEach((selectedFilterElem) => {
    const category = selectedFilterElem.getAttribute('data-product-filter-category');
    const value = selectedFilterElem.getAttribute('data-product-filter-value');
    const label = selectedFilterElem.getAttribute('data-product-filter-label');
    const hex = selectedFilterElem.getAttribute('data-product-filter-hex');

    // Add initially selected filters to array
    if (selectedFilterElem.classList.contains('active')) {
      selectedFilters.push({
        category,
        value,
        label: label || value,
        hex,
      });
    }

    // Handle adding filter on click
    selectedFilterElem.addEventListener('click', () => {
      const isFilterSelected = selectedFilters.some(
        (filter) => filter.category === category && filter.value === value,
      );

      if (!isFilterSelected) {
        selectedFilters.push({
          category,
          value,
          label: label || value,
          hex,
        });
      } else if (category === 'colors' && isFilterSelected) {
        selectedFilters = selectedFilters.filter(
          (filter) => filter.category !== category || filter.value !== value,
        );
      } else if (isFilterSelected) {
        return;
      }

      selectedFilterElem.classList.toggle('active');
    });

    // Handle removing filter on inner button click
    if (category !== 'colors') {
      findElem('button', selectedFilterElem).addEventListener('click', (event) => {
        event.stopPropagation();

        selectedFilters = selectedFilters.filter(
          (filter) => filter.category !== category || filter.value !== value,
        );

        const filterValueElem = findElem(
          `[data-product-filter-category="${category}"][data-product-filter-value="${value}"]`,
        );

        filterValueElem.classList.remove('active');
      });
    }
  });

  displaySelectedFilters();

  if (onInitFilters) {
    onInitFilters(selectedFilters);
  }

  window.addEventListener('popstate', () => {
    const filterUrlSearch = window.location.search.substr(1);

    if (!filterUrlSearch) {
      return;
    }

    selectedFilters = [];

    filterValueElems.forEach((selectedFilterElem) => {
      selectedFilterElem.classList.remove('active');
    });

    filterUrlSearch.split('&').forEach((param) => {
      const [name, values] = param.split('=');

      const filterMatch = name.match(
        new RegExp(`/^${filterUrlPrefix}[(themes|years|colors|artists)]$/`),
      );
      // const filterMatch = name.match(/^collection_filter\[(themes|years|colors|artists)\]$/);

      if (filterMatch && filterMatch[1]) {
        const category = filterMatch[1];

        decodeURIComponent(values)
          .split(',')
          .forEach((value) => {
            const filterValueElem = findElem(
              `[data-product-filter-category="${category}"][data-product-filter-value="${value}"]`,
            );

            if (!filterValueElem) {
              return;
            }

            filterValueElem.classList.add('active');

            const label = filterValueElem.getAttribute('data-product-filter-label');
            const hex = filterValueElem.getAttribute('data-product-filter-hex');

            selectedFilters.push({
              category,
              value,
              label: label || value,
              hex,
            });
          });
      }
    });

    searchByFilters();
  });

  // Clear filter values
  clearFiltersBtns.forEach((filtersClearBtn) => {
    filtersClearBtn.addEventListener('click', () => {
      selectedFilters = [];
      findAllElems(
        `[data-product-filter-category][data-product-filter-value].active`,
      ).forEach((elem) => elem.classList.remove('active'));
      popupElem.classList.remove('active');

      searchByFilters(true);
    });
  });

  function closePopup(shouldResetFiltersToLast) {
    popupElem.classList.remove('active');
    document.removeEventListener('keyup', closePopupOnClickEscape);
    document.body.classList.remove('overflow-hidden');

    if (shouldResetFiltersToLast) {
      selectedFilters = lastSelectedFilters;

      findAllElems('.active[data-product-filter-value]').forEach((filterValueElem) =>
        filterValueElem.classList.remove('active'),
      );

      selectedFilters.forEach((selectedFilter) => {
        const filterValueElem = findElem(
          `[data-product-filter-category="${selectedFilter.category}"][data-product-filter-value="${selectedFilter.value}"]`,
        );

        filterValueElem.classList.add('active');
      });
    }
  }

  function closePopupOnClickEscape(event) {
    if (event.keyCode === ESCAPE_KEY_CODE) {
      closePopup(true);
    }
  }

  function displaySelectedFilters() {
    filtersValuesElem.innerHTML = '';
    selectedFilters.forEach(({ category, label, value, hex }) => {
      let filterValueElem = null;
      switch (category) {
        case 'years':
        case 'themes':
        case 'artists': {
          filterValueElem = productFilterValueTemplateElem.cloneNode(true);
          findElem('.product-filters__value-text', filterValueElem).textContent = label;
          break;
        }
        case 'colors': {
          filterValueElem = productFilterValueColorTemplateElem.cloneNode(true);
          findElem('.product-filters__value-color', filterValueElem).style.backgroundColor = hex;
          findElem('.product-filters__value-text', filterValueElem).textContent = label;
          break;
        }
      }

      findElem('button', filterValueElem).addEventListener('click', () => {
        selectedFilters = selectedFilters.filter(
          (filter) => filter.category !== category || filter.value !== value,
        );
        findElem(
          `[data-product-filter-category="${category}"][data-product-filter-value="${value}"]`,
        ).classList.remove('active');

        searchByFilters(true);
      });

      filtersValuesElem.appendChild(filterValueElem);
    });

    if (selectedFilters.length) {
      clearFiltersBtns.forEach((elem) => elem.removeAttribute('disabled'));
    } else {
      clearFiltersBtns.forEach((elem) => elem.setAttribute('disabled', ''));
    }
  }

  async function searchByFilters(pushToHistory) {
    closePopup();
    displaySelectedFilters();
    const filtersUrlSearch = generateProductFiltersUrlSearch(selectedFilters, filterUrlPrefix);

    const { response, error } = await waitPromise(
      fetch(`/b2c_api/v1${window.location.pathname}${filtersUrlSearch}`),
    );

    if (error) {
      showErrorNotification(findElem('#searchErrorMessage').content.textContent);
      return;
    }

    const data = await response.text();
    collectionItems.innerHTML = data;

    if (pushToHistory) {
      window.history.pushState(
        undefined,
        undefined,
        `${window.location.origin}${window.location.pathname}${filtersUrlSearch}`,
      );
    }

    if (onFilter) {
      onFilter(selectedFilters);
    }
  }

  function initColorsSection() {
    const colorsCategory = findElem('.product-filters__popup-colors-groups');
    const colorBtnElems = [];

    if (!window.COLOR_GROUPS) {
      return;
    }

    window.COLOR_GROUPS.forEach(({ id, name, hex, active }) => {
      const colorBtnElem = document.createElement('button');
      colorBtnElem.style.backgroundColor = hex;
      colorBtnElem.setAttribute('data-product-filter-category', 'colors');
      colorBtnElem.setAttribute('data-product-filter-value', id);
      colorBtnElem.setAttribute('data-product-filter-label', name);
      colorBtnElem.setAttribute('data-product-filter-hex', hex);
      colorBtnElem.classList.toggle('active', !!active);

      const colorTextElem = document.createElement('span');
      colorTextElem.classList.add('tooltip-content');
      colorTextElem.textContent = name;
      colorBtnElem.appendChild(colorTextElem);

      const colorTickElem = document.createElement('i');
      colorTickElem.classList.add('icon-tick');
      colorBtnElem.appendChild(colorTickElem);

      colorsCategory.appendChild(colorBtnElem);
      colorBtnElems.push(colorBtnElem);
    });

    const colorsSearchInputElem = findElem('.product-filters__popup-colors-search input');
    colorsSearchInputElem.addEventListener('keyup', () => {
      const inputValue = colorsSearchInputElem.value.toLowerCase();
      colorBtnElems.forEach((colorBtnElem) => {
        colorBtnElem.classList.toggle(
          'non-relevant',
          colorBtnElem.textContent.toLowerCase().indexOf(inputValue) === -1,
        );
      });
    });
  }
}

export function generateProductFiltersUrlSearch(selectedFilters, filterUrlPrefix) {
  const colorFilters = [];
  const yearFilters = [];
  const themeFilters = [];
  const artistFilters = [];

  selectedFilters.forEach(({ category, value }) => {
    switch (category) {
      case 'years': {
        yearFilters.push(value);
        break;
      }
      case 'themes': {
        themeFilters.push(value);
        break;
      }
      case 'artists': {
        artistFilters.push(value);
        break;
      }
      case 'colors': {
        colorFilters.push(value);
        break;
      }
    }
  });

  const params = [];
  if (yearFilters.length) {
    params.push(`${filterUrlPrefix}[years]=${encodeURIComponent(yearFilters.join(','))}`);
  }
  if (themeFilters.length) {
    params.push(`${filterUrlPrefix}[themes]=${encodeURIComponent(themeFilters.join(','))}`);
  }
  if (artistFilters.length) {
    params.push(`${filterUrlPrefix}[artists]=${encodeURIComponent(artistFilters.join(','))}`);
  }
  if (colorFilters.length) {
    params.push(`${filterUrlPrefix}[colors]=${encodeURIComponent(colorFilters.join(','))}`);
  }

  return params.length ? `?${params.join('&')}` : '';
}
