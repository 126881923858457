import { findElem } from '../helpers/domHelpers';

const notifications = [];
let notificationsAreBeingShown = false;

async function handleNotifications() {
  notificationsAreBeingShown = true;
  const notification = notifications.shift();

  await showNotification(notification.template, notification.text);

  if (notifications.length) {
    handleNotifications();
  } else {
    notificationsAreBeingShown = false;
  }
}

export function showSuccessNotification(text) {
  const notificationSuccessTemplate = findElem('#notificationSuccessTemplate');
  if (!notificationSuccessTemplate) {
    return;
  }

  notifications.push({
    text,
    template: notificationSuccessTemplate.content,
  });

  if (!notificationsAreBeingShown) {
    handleNotifications();
  }
}

export function showErrorNotification(text) {
  const notificationErrorTemplate = findElem('#notificationErrorTemplate');
  if (!notificationErrorTemplate) {
    return;
  }

  notifications.push({
    text,
    template: notificationErrorTemplate.content,
  });

  if (!notificationsAreBeingShown) {
    handleNotifications();
  }
}

function showNotification(notificationTemplate, text) {
  return new Promise((resolve) => {
    const notificationElem = findElem('.notification', notificationTemplate.cloneNode(true));
    findElem('.notification__message', notificationElem).textContent = text;

    const hideNotification = () => {
      notificationElem.classList.add('notification--leave');
      notificationElem.addEventListener('animationend', () => {
        notificationElem.remove();
        resolve();
      });
    };

    const timeout = setTimeout(() => {
      hideNotification();
    }, 5000);

    findElem('.notification__close-btn', notificationElem).addEventListener('click', () => {
      clearTimeout(timeout);
      hideNotification();
    });

    document.body.appendChild(notificationElem);
  });
}
