import { findAllElems, findElem } from '../../helpers/domHelpers';
import { showErrorNotification, showSuccessNotification } from '../notifications';
import { initSelectFormField } from './select';
import {
  getValidationEmail,
  getValidationMaxlen,
  getValidationMinlen,
  getValidationRepeatPassword,
  getValidationRequired,
  getValidationUrl,
  validateRepeatPassword,
} from './validators';

export function initFormFields() {
  findAllElems('form').forEach((formElem) => {
    const formFieldValidators = [];

    findAllElems('.form-field', formElem).forEach((formFieldElem) => {
      const inputElem =
        findElem('input', formFieldElem) ||
        findElem('textarea', formFieldElem) ||
        findElem('select', formFieldElem);

      const inputElemType = inputElem.getAttribute('type');
      const inputElemTagName = inputElem.tagName.toLowerCase();

      if (
        ['text', 'password', 'checkbox', 'email'].includes(inputElemType) ||
        ['textarea', 'select'].includes(inputElemTagName)
      ) {
        handleValueChange(inputElem, formFieldElem);
        handleFocusOnClick(inputElem, formFieldElem);
        handleFocus(inputElem, formFieldElem);
      }

      if (inputElemTagName === 'select') {
        initSelectFormField(formFieldElem);
      }

      const validators = [];

      if (inputElem.hasAttribute('validation-required')) {
        validators.push(getValidationRequired(inputElem, inputElemType));
      }

      if (inputElem.hasAttribute('validation-email')) {
        validators.push(getValidationEmail(inputElem));
      }

      if (inputElem.hasAttribute('validation-repeat-password')) {
        validators.push(getValidationRepeatPassword(inputElem, formElem));
      }

      if (inputElem.hasAttribute('validation-minlen')) {
        validators.push(getValidationMinlen(inputElem));
      }

      if (inputElem.hasAttribute('validation-maxlen')) {
        validators.push(getValidationMaxlen(inputElem));
      }

      if (inputElem.hasAttribute('validation-url')) {
        validators.push(getValidationUrl(inputElem));
      }

      if (validators.length) {
        const errorElement = findElem('.form-field__error', formFieldElem);

        const validate = (validateRepeat = true) => {
          formFieldElem.classList.add('form-field--touched');

          const invalidValidator = findInvalidValidator(validators);
          formFieldElem.classList.toggle('form-field--error', !!invalidValidator);

          if (validateRepeat && inputElem.hasAttribute('validation-repeat-password')) {
            validateRepeatPassword(inputElem, formElem, formFieldValidators);
          }

          if (invalidValidator) {
            errorElement.textContent = invalidValidator.errorMessage;
          }
        };

        formFieldValidators.push({
          inputElem,
          validate,
        });

        if (inputElemType === 'checkbox') {
          inputElem.addEventListener('change', () => {
            formFieldElem.classList.add('form-field--touched');
            validate();
          });
        } else {
          inputElem.addEventListener('blur', () => {
            formFieldElem.classList.add('form-field--touched');
            validate();
          });

          inputElem.addEventListener('keyup', () => {
            if (formFieldElem.classList.contains('form-field--touched')) {
              validate();
            }
          });
        }
      }
    });

    // eslint-disable-next-line no-param-reassign
    formElem.validateForm = () => formFieldValidators.forEach(({ validate }) => validate());

    formElem.addEventListener('submit', (event) => {
      formElem.validateForm();

      if (findElem('.form-field--error', formElem)) {
        event.preventDefault();
      } else if (formElem.getAttribute('action')) {
        const submitBtn = findElem('button[type="submit"]', formElem);

        if (submitBtn) {
          submitBtn.classList.add('loading');
          submitBtn.setAttribute('disabled', true);
        }

        const isAjaxForm = formElem.hasAttribute('data-is-ajax-form');

        if (!isAjaxForm) {
          return;
        }

        const formMethod = formElem.getAttribute('method');
        const formUrl = formElem.getAttribute('action');
        const formData = new FormData(formElem);
        event.preventDefault();
        makeFetchRequest(formUrl, formMethod, formData)
          .then((response) => {
            findAllElems('.form-field--touched', formElem).forEach((formFieldElem) =>
              formFieldElem.classList.remove('form-field--touched'),
            );
            formElem.reset();

            const successMessage = formElem.getAttribute('data-success-message');

            if (successMessage) {
              showSuccessNotification(successMessage);
            }

            const submitSuccessEvent = new CustomEvent('submitsuccess', {
              detail: { response, formData, url: formUrl },
            });
            formElem.dispatchEvent(submitSuccessEvent);
          })
          .catch((error) => {
            const errorMessage = formElem.getAttribute('data-error-message');

            if (errorMessage) {
              showErrorNotification(errorMessage);
            }

            const submitErrorEvent = new CustomEvent('submiterror', {
              detail: { error, formData, url: formUrl },
            });
            formElem.dispatchEvent(submitErrorEvent);
          })
          .finally(() => {
            submitBtn.classList.remove('loading');
            submitBtn.removeAttribute('disabled');
          });
      }
    });
  });
}

function makeFetchRequest(url, method, formData) {
  return fetch(url, {
    method,
    body: formData,
  }).then((response) => {
    if (response.ok) {
      return response;
    }

    throw new Error(response);
  });
}

function findInvalidValidator(validators) {
  return validators.find(({ func }) => !func());
}

function getInputValue(inputElem) {
  return inputElem.getAttribute('type') === 'checkbox' ? inputElem.checked : inputElem.value;
}

function handleValueChange(inputElem, formFieldElem) {
  let filledClassAdded =
    !!getInputValue(inputElem) && formFieldElem.classList.contains('form-field--filled');

  filledClassAdded = handleAddingFilledClass(filledClassAdded, inputElem, formFieldElem);

  inputElem.addEventListener('change', () => {
    filledClassAdded = handleAddingFilledClass(filledClassAdded, inputElem, formFieldElem);
  });
}

function handleAddingFilledClass(filledClassAdded, inputElem, formFieldElem) {
  const value = getInputValue(inputElem);

  if (!filledClassAdded && value) {
    formFieldElem.classList.add('form-field--filled');

    return true;
  }
  if (filledClassAdded && !value) {
    formFieldElem.classList.remove('form-field--filled');

    return false;
  }

  return filledClassAdded;
}

function handleFocusOnClick(inputElem, formFieldElem) {
  const formFieldWrapperElem = findElem('.form-field__wrapper', formFieldElem);
  formFieldWrapperElem.addEventListener('click', () => {
    inputElem.focus();
  });
}

function handleFocus(inputElem, formFieldElem) {
  inputElem.addEventListener('focus', () => {
    formFieldElem.classList.add('form-field--focused');
  });

  inputElem.addEventListener('blur', () => {
    formFieldElem.classList.remove('form-field--focused');
  });
}
