import { sliders } from '../components/slider';
import { findAllElems, findElem, removeClassByClassName } from '../helpers/domHelpers';

export function initHomePage() {
  if (!findElem('.home-page')) {
    return;
  }

  prepareSliderPropsForArtistsSlider();
  prepareSliderPropsForCollectionsSlider();

  enhanceNewsSlider();
}

function prepareSliderPropsForCollectionsSlider() {
  const sliderElement = findElem('.collections__slider');
  if (!sliderElement) {
    return;
  }

  const slideElements = findAllElems('.slider__slide', sliderElement);
  if (slideElements.length === 3) {
    const slidesWrapperElement = findElem('.slider__slides', sliderElement);
    slideElements.forEach((slideElement) => {
      const slideCopy = slideElement.cloneNode(true);
      slideCopy.classList.add('slider__slide--copy');
      slidesWrapperElement.appendChild(slideCopy);
    });
  }

  sliderElement._sliderProps = {
    responsive: {
      769: {
        items: 3,
      },
    },
    itemsForCalculation: slideElements.length === 3 ? 4 : 1,
    onInit: (slider) => {
      const onNext = () => slider.goTo('next');
      const onPrev = () => slider.goTo('prev');

      const addPrevNextSlidesListeners = () => {
        const slides = Array.from(slider.getInfo().slideItems);
        const activeSlides = slides.filter((elem) => elem.classList.contains('tns-slide-active'));
        slides.forEach((slideElement) => {
          slideElement.removeEventListener('click', onPrev);
          slideElement.removeEventListener('click', onNext);
        });

        if (activeSlides.length > 1 && activeSlides[0]) {
          activeSlides[0].addEventListener('click', onPrev);
        }

        if (activeSlides.length > 1 && activeSlides[2]) {
          activeSlides[2].addEventListener('click', onNext);
        }
      };

      slider.events.on('indexChanged', addPrevNextSlidesListeners);
      addPrevNextSlidesListeners();
    },
  };
}

function enhanceNewsSlider() {
  const sliderElement = findElem('.news__slider');
  if (!sliderElement) {
    return;
  }

  sliderElement._sliderProps = {
    onInit: (slider) => {
      const associatedSlideImages = findAllElems('.news__associated-slide-image');

      slider.events.on('transitionStart', ({ navCurrentIndex }) => {
        removeClassByClassName('news__associated-slide-image--active');
        associatedSlideImages[navCurrentIndex].classList.add(
          'news__associated-slide-image--active',
        );
      });
    },
  };
}

function prepareSliderPropsForArtistsSlider() {
  const artistsSliderElement = findElem('.artists-and-projects__artists-slider');
  const projectsSliderElement = findElem('.artists-and-projects__projects-slider');

  if (projectsSliderElement) {
    projectsSliderElement._sliderProps = {
      mouseDrag: false,
      touch: false,
    };
  }

  if (artistsSliderElement) {
    artistsSliderElement._sliderProps = {
      onInit: (slider) => {
        slider.events.on('transitionStart', ({ displayIndex }) => {
          const projectsSlider = sliders[projectsSliderElement.id];
          projectsSlider.goTo(displayIndex - 1);
        });
      },
    };
  }
}
