import { findElem } from '../helpers/domHelpers';
import { waitPromise } from '../helpers/waitPromise';

export class InfiniteScroll {
  page = 2;

  url = null;

  container = null;

  requestInProgress = false;

  noMoreData = false;

  constructor(selector, url) {
    this.container = findElem(selector);
    this.url = url;

    if (!this.container) {
      return;
    }

    window.addEventListener('scroll', () => {
      if (this.requestInProgress || this.noMoreData) {
        return;
      }

      if (
        document.documentElement.scrollTop + window.innerHeight >
        this.container.offsetTop + this.container.offsetHeight - 500
      ) {
        this.requestInProgress = true;

        (async () => {
          const params = this.url.includes('?') ? `&page=${this.page}` : `?page=${this.page}`;
          const { response, error } = await waitPromise(fetch(`${this.url}${params}`));

          if (error) {
            this.requestInProgress = false;
            return;
          }

          const data = await response.text();

          if (!data.trim()) {
            this.noMoreData = true;
            return;
          }

          const wrapper = document.createElement('div');
          wrapper.innerHTML = data;
          wrapper.childNodes.forEach((elem) => {
            this.container.appendChild(elem);
          });

          this.page += 1;
          this.requestInProgress = false;
        })();
      }
    });
  }

  updateUrl(url) {
    this.url = url;
    this.page = 2;
    this.noMoreData = false;
  }
}
