import { InfiniteScroll } from '../components/infinite-scroll';
import { generateProductFiltersUrlSearch, initProductFilters } from '../components/product-filters';
import { findElem } from '../helpers/domHelpers';

export function initCollectionPage() {
  if (!findElem('.collection-page')) {
    return;
  }

  let infiniteScroll;

  initProductFilters({
    itemsWrapperSelector: '.collection-page__items',
    filterUrlPrefix: 'collection_filter',
    onInitFilters: initInfiniteScroll,
    onFilter: updateInfiniteScroll,
  });

  function initInfiniteScroll(selectedFilters) {
    infiniteScroll = new InfiniteScroll(
      '.collection-page__items',
      `/b2c_api/v1${window.location.pathname}${generateProductFiltersUrlSearch(selectedFilters)}`,
    );
  }

  function updateInfiniteScroll(selectedFilters) {
    infiniteScroll.updateUrl(
      `/b2c_api/v1${window.location.pathname}${generateProductFiltersUrlSearch(selectedFilters)}`,
    );
  }
}
