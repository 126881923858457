import { TAB_KEY_CODE } from './constants';

export function getTabKeyHandler(focusableElems) {
  return (event) => {
    if (event.keyCode === TAB_KEY_CODE) {
      if (focusableElems.length === 1) {
        event.preventDefault();
      }

      if (event.shiftKey) {
        handleBackwardTab();
      } else {
        handleForwardTab();
      }
    }

    function handleBackwardTab() {
      if (document.activeElement === focusableElems[0]) {
        event.preventDefault();
        focusableElems[focusableElems.length - 1].focus();
      }
    }

    function handleForwardTab() {
      if (document.activeElement === focusableElems[focusableElems.length - 1]) {
        event.preventDefault();
        focusableElems[0].focus();
      }
    }
  };
}
