import { findAllElems, findElem } from '../helpers/domHelpers';
import { showErrorNotification, showSuccessNotification } from './notifications';

export function handleGeneralServerSideNotifications() {
  const alert = findElem('.alert');

  if (!alert) {
    return;
  }

  const generalAlert = findElem('span', alert);

  if (!generalAlert) {
    return;
  }

  if (alert.classList.contains('alert-danger')) {
    showErrorNotification(generalAlert.textContent);
  } else if (alert.classList.contains('alert-success')) {
    showSuccessNotification(generalAlert.textContent);
  }
}

export function handleFormServerSideErrors() {
  const alert = findElem('.alert');

  if (!alert) {
    return;
  }
  const formErrorElems = findAllElems('li', alert);

  formErrorElems.forEach((elem) => {
    const inputElem = findElem(`[name="spree_user[${elem.getAttribute('data-key')}]"]`);

    if (!inputElem) {
      return;
    }

    const formField = inputElem.closest('.form-field');

    formField.classList.add('form-field--error');
    findElem('.form-field__error', formField).textContent = elem.textContent;
  });
}
