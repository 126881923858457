import {
  ARROW_UP_KEY_CODE,
  ARROW_DOWN_KEY_CODE,
  FOCUSABLE_SELECTOR,
  ESCAPE_KEY_CODE,
  ENTER_KEY_CODE,
} from '../../helpers/constants';
import { findAllElems, findElem } from '../../helpers/domHelpers';
import { getTabKeyHandler } from '../../helpers/getTabKeyHandler';

export function reInitSelectOptions(stateSelectElem, data) {
  findAllElems('option', stateSelectElem).forEach((optionElem) => {
    if (optionElem.getAttribute('value')) {
      optionElem.remove();
    }
  });
  stateSelectElem.closest('.form-field').classList.toggle('hidden', !data.length);

  data.forEach((state) => {
    const optionElem = document.createElement('option');
    optionElem.value = state.id;
    optionElem.textContent = state.label;
    stateSelectElem.appendChild(optionElem);
  });

  if (!data.some(({ id }) => id === stateSelectElem.value)) {
    // eslint-disable-next-line no-param-reassign
    stateSelectElem.value = '';
    stateSelectElem.dispatchEvent(new Event('change'));
  }
}

export function disableSelectFormField(stateSelectElem) {
  stateSelectElem.setAttribute('disabled', '');
  stateSelectElem.closest('.form-field').classList.add('form-field--disabled');
}

export function enableSelectFormField(stateSelectElem) {
  stateSelectElem.removeAttribute('disabled');
  stateSelectElem.closest('.form-field').classList.remove('form-field--disabled');
}

export function toggleSelectFormFieldLoading(stateSelectElem, value) {
  stateSelectElem.closest('.form-field').classList.toggle('form-field--loading', value);
}

export function initSelectFormField(formFieldElem) {
  const formFieldWrapperElem = findElem('.form-field__wrapper', formFieldElem);
  const selectInputElem = findElem('select', formFieldElem);
  const clearValueButton = findElem('.form-field__select-clear', formFieldWrapperElem);

  if (clearValueButton) {
    clearValueButton.addEventListener('click', (event) => {
      event.stopPropagation();
      selectInputElem.value = '';
      selectInputElem.dispatchEvent(new Event('change'));
    });
  }
  const selectValueElem = document.createElement('div');
  selectValueElem.classList.add('form-field__select-value');

  const selectOptionsElem = document.createElement('div');
  selectOptionsElem.classList.add('form-field__select-options');
  selectInputElem.addEventListener('change', () => {
    const selectedOption = findElem(`option[value="${selectInputElem.value}"]`, selectInputElem);
    if (selectedOption) {
      selectValueElem.textContent = selectedOption.textContent;
    }
    let selectedOptionElem = findElem('.form-field__select-option--selected', formFieldElem);

    if (selectedOptionElem) {
      selectedOptionElem.classList.remove('form-field__select-option--selected');
    }

    selectedOptionElem = findElem(
      `.form-field__select-option[value="${selectInputElem.value}"]`,
      formFieldElem,
    );

    if (selectedOptionElem) {
      selectedOptionElem.classList.add('form-field__select-option--selected');
    }

    if (clearValueButton) {
      clearValueButton.classList.toggle('hidden', !selectInputElem.value);
    }
  });

  selectInputElem.addEventListener('focus', () => {
    selectInputElem.addEventListener('keydown', handleEnterKeyDown);
  });

  selectInputElem.addEventListener('blur', () => {
    formFieldElem.classList.remove('form-field--focused');
  });

  initSelectOptions();

  formFieldWrapperElem.addEventListener('click', openSelectOptions);

  formFieldWrapperElem.append(selectValueElem);
  formFieldWrapperElem.append(selectOptionsElem);

  const selectedOption =
    findElem('option[selected]', selectInputElem) ||
    findElem('option:first-child', selectInputElem);
  if (selectedOption) {
    selectValueElem.textContent = selectedOption.textContent;
    selectInputElem.value = selectedOption.getAttribute('value');
  }
  selectInputElem.dispatchEvent(new Event('change'));

  if (clearValueButton) {
    clearValueButton.classList.toggle('hidden', !selectInputElem.value);
  }

  const observer = new MutationObserver(initSelectOptions);

  observer.observe(selectInputElem, {
    subtree: true,
    childList: true,
    attributes: false,
  });

  selectInputElem.setAttribute('aria-hidden', 'true');

  function initSelectOptions() {
    selectOptionsElem.innerHTML = '';
    findAllElems('option', selectInputElem).forEach((optionElem) => {
      const optionElemValue = optionElem.getAttribute('value');

      if (!optionElemValue) {
        return;
      }
      const selectOptionElem = document.createElement('button');
      selectOptionElem.classList.add('form-field__select-option');
      selectOptionElem.textContent = optionElem.textContent;
      selectOptionElem.setAttribute('value', optionElemValue);
      selectOptionElem.setAttribute('tabindex', '-1');
      selectOptionElem.setAttribute('type', 'button');
      selectOptionElem.addEventListener('click', (event) => {
        event.stopPropagation();

        if (selectInputElem.value !== optionElemValue) {
          selectInputElem.value = optionElemValue;
          selectInputElem.dispatchEvent(new Event('change'));
        }

        closeSelectOptions();
      });
      selectOptionsElem.append(selectOptionElem);
    });
  }
  function handleClickOutside(event) {
    if (!formFieldElem.contains(event.target)) {
      closeSelectOptions();
      document.removeEventListener('click', handleClickOutside);
    }
  }

  let onKeyDownTabHandler = null;
  let onKeyDownArrowHandler = null;
  let lastFocusedElement = null;

  function openSelectOptions() {
    if (
      selectInputElem.hasAttribute('disabled') ||
      formFieldElem.classList.contains('form-field--active')
    ) {
      return;
    }

    formFieldWrapperElem.removeEventListener('click', openSelectOptions);
    selectInputElem.removeEventListener('keydown', handleEnterKeyDown);

    findAllElems('.form-field__select-option', selectOptionsElem).forEach((selectOptionElem) => {
      selectOptionElem.removeAttribute('tabindex');
    });

    document.addEventListener('keyup', handleEscapeKeyUp);

    const focusableOptions = findAllElems(FOCUSABLE_SELECTOR, selectOptionsElem);
    onKeyDownTabHandler = getTabKeyHandler(focusableOptions);
    document.addEventListener('keydown', onKeyDownTabHandler);

    onKeyDownArrowHandler = (event) => {
      handleArrowKeyDown(event, focusableOptions);
    };
    document.addEventListener('keydown', onKeyDownArrowHandler);

    formFieldElem.classList.add('form-field--active');
    document.addEventListener('click', handleClickOutside);

    lastFocusedElement = document.activeElement;
    focusableOptions[0].focus();
  }

  function closeSelectOptions() {
    findAllElems('.form-field__select-option', selectOptionsElem).forEach((selectOptionElem) => {
      selectOptionElem.setAttribute('tabindex', '-1');
    });

    formFieldElem.classList.remove('form-field--active');
    document.removeEventListener('click', handleClickOutside);
    document.removeEventListener('keydown', onKeyDownTabHandler);
    document.removeEventListener('keydown', onKeyDownArrowHandler);
    document.removeEventListener('keyup', handleEscapeKeyUp);
    formFieldWrapperElem.addEventListener('click', openSelectOptions);

    if (lastFocusedElement) {
      lastFocusedElement.focus();
    }
  }

  function handleEnterKeyDown(event) {
    if (event.keyCode === ENTER_KEY_CODE) {
      event.preventDefault();
      openSelectOptions();
    }
  }

  function handleEscapeKeyUp(event) {
    if (event.keyCode === ESCAPE_KEY_CODE) {
      closeSelectOptions();
    }
  }

  function handleArrowKeyDown(event, focusableOptions) {
    if (![ARROW_UP_KEY_CODE, ARROW_DOWN_KEY_CODE].includes(event.keyCode)) {
      return;
    }

    event.preventDefault();
    const focusedOptionIndex = focusableOptions.findIndex(
      (elem) => document.activeElement === elem,
    );
    if (event.keyCode === ARROW_DOWN_KEY_CODE) {
      if (focusedOptionIndex === focusableOptions.length - 1) {
        focusableOptions[0].focus();
      } else {
        focusableOptions[focusedOptionIndex + 1].focus();
      }
    } else if (focusedOptionIndex === 0) {
      focusableOptions[focusableOptions.length - 1].focus();
    } else {
      focusableOptions[focusedOptionIndex - 1].focus();
    }
  }
}
