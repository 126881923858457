import { findAllElems, findElem } from '../helpers/domHelpers';

export function initSearchPage() {
  if (!findElem('.search-page')) {
    return;
  }

  const tabElems = findAllElems('.search-page__tab');
  const containers = findAllElems('.search-page__container');

  tabElems.forEach((elem) => {
    const targetId = elem.getAttribute('target-id');
    const targetContainer = findElem(`#${targetId}`);
    elem.addEventListener('click', () => {
      tabElems.forEach((tab) => tab.classList.remove('search-page__tab--active'));
      containers.forEach((container) => container.classList.add('hidden'));
      elem.classList.add('search-page__tab--active');
      targetContainer.classList.remove('hidden');

      const url = new URL(window.location.href);
      url.searchParams.set('tab', targetId);
      window.history.pushState(null, null, url.href);
    });
  });

  const url = new URL(window.location.href);
  const urlTabParam = url.searchParams.get('tab');
  const targetTabElem = tabElems.find((elem) => elem.getAttribute('target-id') === urlTabParam);
  if (targetTabElem) {
    targetTabElem.click();
  }
}
