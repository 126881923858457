import { showErrorNotification, showSuccessNotification } from '../components/notifications';
import { sliders } from '../components/slider';
import { findElem, findAllElems } from '../helpers/domHelpers';
import { showroomMarkers } from '../components/showrooms';
import { initLightBox } from '../components/light-box';
import { initModals } from '../components/modal';
import { parseJSONResponse } from '../helpers/parseResponse';
import { reInitSelectOptions } from '../components/form/select';

export function initProductPage() {
  if (!findElem('.product-page')) {
    return;
  }

  initFeaturesSwitch();
  enhanceFeatureSlider();
  enhanceDescriptionSlider();
  enhanceMaterialsSlider();
  extendColorOptionsSlider();
  initProductShowrooms();
  initAddToMoodboardForm();
}

function initFeaturesSwitch() {
  findAllElems('[data-material-feature-trigger-id]').forEach((triggerElem) => {
    const parent = triggerElem.closest('.slider__slide');
    const dataMaterialFeatureTriggerElems = findAllElems(
      '[data-material-feature-trigger-id]',
      parent,
    );
    const dataMaterialFeatureContentElems = findAllElems(
      '[data-material-feature-content-id]',
      parent,
    );
    triggerElem.addEventListener('click', () => {
      dataMaterialFeatureTriggerElems.forEach((elem) => elem.classList.remove('active'));
      dataMaterialFeatureContentElems.forEach((elem) => elem.classList.remove('active'));
      triggerElem.classList.add('active');
      const featureId = triggerElem.getAttribute('data-material-feature-trigger-id');
      findElem(`[data-material-feature-content-id="${featureId}"]`).classList.add('active');
    });
  });
}

function enhanceFeatureSlider() {
  const featuresSliderElement = findElem('.product-page__materials-features-slider');
  featuresSliderElement._sliderProps = {
    mouseDrag: false,
    touch: false,
    loop: false,
  };
}

function enhanceDescriptionSlider() {
  const descriptionSliderElement = findElem('.product-page__materials-description-slider');
  descriptionSliderElement._sliderProps = {
    mouseDrag: false,
    touch: false,
    loop: false,
  };
}

async function extendColorOptionsSlider() {
  const productSliderElem = findElem('.product-page__product-slider');
  const colorOptionsSliderElem = findElem('.product-page__color-options-slider');

  let colorOptionsSlider = null;

  let toResolve = null;
  const promise = new Promise((resolve) => {
    toResolve = resolve;
  });

  productSliderElem._sliderProps = {
    onInit: toResolve,
  };

  colorOptionsSliderElem._sliderProps = {
    onInit: (slider) => {
      colorOptionsSlider = slider;
      promise.then(() => {
        handleColorOptionsSliderVisibility(colorOptionsSliderElem, colorOptionsSlider);
        initColorOptionsActions();
      });
    },
  };
}

function initProductShowrooms() {
  findAllElems('[data-modal-target="#showProductShowroomsModal"]').forEach((elem) => {
    const productShowroomIds = elem.getAttribute('data-showroom-ids').split(',');

    elem.addEventListener('click', () => {
      let showroomsMap = null;
      const bounds = new window.google.maps.LatLngBounds();
      showroomMarkers.forEach(({ marker, map, showroomId }) => {
        showroomsMap = map;
        marker.setMap(productShowroomIds.includes(showroomId) ? map : null);

        if (productShowroomIds.includes(showroomId)) {
          bounds.extend(marker.getPosition());
          marker.setMap(map);
        } else {
          marker.setMap(null);
        }
      });

      showroomsMap.setCenter(bounds.getCenter());
      showroomsMap.fitBounds(bounds);
      showroomsMap.setZoom(showroomsMap.getZoom() - 1);
    });
  });
}

function initColorOptionsSynchronization(productSlider) {
  findAllElems('[data-product-target-image-id]').forEach((elem) => {
    const productImageId = elem.getAttribute('data-product-target-image-id');
    const slides = Array.from(productSlider.getInfo().slideItems).filter(
      (slideElem) => !slideElem.classList.contains('tns-slide-cloned'),
    );

    elem.addEventListener('click', () => {
      const index = slides.findIndex(
        (slideElem) => slideElem.getAttribute('data-product-image-id') === productImageId,
      );

      productSlider.goTo(index);
    });
  });
}

function handleColorOptionsSliderVisibility(sliderElem, _slider) {
  if (sliderElem.hasAttribute('data-on-media-change-listener')) {
    return;
  }

  sliderElem.setAttribute('data-on-media-change-listener', '');
  let slider = _slider;
  const mediaQuery = window.matchMedia(`(min-width: 769px)`);

  mediaQuery.addEventListener('change', ({ matches }) => {
    if (matches) {
      slider.destroy();

      initColorOptionsActions();
    } else {
      slider.rebuild();
      slider = sliders[sliderElem.id];
    }
  });

  if (mediaQuery.matches) {
    slider.destroy();
  }
}

function initColorOptionsActions() {
  const productSliderElem = findElem('.product-page__product-slider');
  initColorOptionsSynchronization(sliders[productSliderElem.id]);
  initAddingProductToFavourites();
  initAddToMoodboardToggles();
  initLightBox();
  initModals();
}

function initAddingProductToFavourites() {
  findAllElems('.product-page__toggle-product-to-favourites').forEach((buttonElem) => {
    const productId = buttonElem.getAttribute('data-product-id');
    buttonElem.addEventListener('click', () => {
      buttonElem.setAttribute('disabled', '');

      const likesBtnCountElem = findElem('.likes-btn__count');
      const likesBtnCount = parseInt(likesBtnCountElem.textContent, 10);

      const deltaCount = buttonElem.classList.contains('active') ? -1 : 1;
      likesBtnCountElem.textContent = `${likesBtnCount + deltaCount}`;

      let requestUrl;
      let method;

      const formData = new FormData();
      if (buttonElem.classList.contains('active')) {
        requestUrl = `/favorites/${productId}`;
        method = 'DELETE';
      } else {
        requestUrl = `/favorites`;
        method = 'POST';
        formData.append('id', productId);
      }

      fetch(requestUrl, { method, body: formData }).then((response) => {
        if (!response.ok) {
          buttonElem.classList.toggle('active');
          likesBtnCountElem.textContent = `${likesBtnCount}`;

          const errorMessageTemplateElem = findElem(
            buttonElem.classList.contains('active')
              ? '#removeFromFavouritesErrorMessage'
              : '#addToFavouritesErrorMessage',
          );

          if (errorMessageTemplateElem) {
            showErrorNotification(errorMessageTemplateElem.content.textContent);
          }
        } else {
          const successMessageTemplateElem = findElem(
            buttonElem.classList.contains('active')
              ? '#addToFavouritesSuccessMessage'
              : '#removeFromFavouritesSuccessMessage',
          );

          showSuccessNotification(successMessageTemplateElem.content.textContent);
        }

        buttonElem.removeAttribute('disabled');
      });

      buttonElem.classList.toggle('active');
    });
  });
}

function initAddToMoodboardForm() {
  const modalElem = findElem('#addToMoodboardModal');
  const showExistingFormToggleElem = findElem('#addToMoodboardModalSelectExisting', modalElem);
  const showNewFormToggleElem = findElem('#addToMoodboardModalCreateNew', modalElem);
  const existingFormElem = findElem('.product-page__moodboard-modal-form-existing', modalElem);
  const newFormElem = findElem('.product-page__moodboard-modal-form-new', modalElem);

  showExistingFormToggleElem.addEventListener('click', () => {
    showNewFormToggleElem.classList.remove('active');
    newFormElem.classList.add('hidden');

    showExistingFormToggleElem.classList.add('active');
    existingFormElem.classList.remove('hidden');
  });

  showNewFormToggleElem.addEventListener('click', () => {
    showExistingFormToggleElem.classList.remove('active');
    existingFormElem.classList.add('hidden');

    showNewFormToggleElem.classList.add('active');
    newFormElem.classList.remove('hidden');
  });

  [existingFormElem, newFormElem].forEach((formElem) => {
    formElem.addEventListener('submitsuccess', () => {
      findElem('.modal__close-btn', modalElem).click();

      const successMessageTemplateElem = findElem('#addToMoodboardSuccessMessage');
      showSuccessNotification(successMessageTemplateElem.content.textContent);
    });

    formElem.addEventListener('submiterror', () => {
      const errorMessageTemplateElem = findElem('#addToMoodboardErrorMessage');
      showErrorNotification(errorMessageTemplateElem.content.textContent);
    });
  });

  newFormElem.addEventListener('submitsuccess', async (event) => {
    const json = await event.detail.response.json();
    const moodboard = parseJSONResponse(json);

    const moodboardSelectElem = findElem('select', existingFormElem);
    const options = findAllElems('option', moodboardSelectElem).map((optionElem) => ({
      id: optionElem.getAttribute('value'),
      label: optionElem.textContent,
    }));
    options.push({
      id: moodboard.id,
      label: moodboard.name,
    });
    reInitSelectOptions(moodboardSelectElem, options);
  });
}

function initAddToMoodboardToggles() {
  const modalElem = findElem('#addToMoodboardModal');
  const nameInputElem = findElem('input[name="name"]', modalElem);

  findAllElems('[data-modal-target="#addToMoodboardModal"]').forEach((elem) => {
    const productId = elem.getAttribute('data-product-id');
    elem.addEventListener('click', () => {
      findAllElems('[name="product_id"]', modalElem).forEach((inputElem) => {
        // eslint-disable-next-line no-param-reassign
        inputElem.value = productId;
      });
      nameInputElem.value = '';
      nameInputElem.dispatchEvent(new Event('change'));
      nameInputElem.closest('.form-field').classList.remove('form-field--touched');
      nameInputElem.closest('.form-field').classList.remove('form-field--error');
    });
  });
}

function enhanceMaterialsSlider() {
  const sliderElem = findElem('.product-page__materials-slider');

  sliderElem._sliderProps = {
    onInit: (slider) => {
      slider.events.on('transitionStart', ({ navCurrentIndex }) => {
        const descriptionSliderElem = findElem('.product-page__materials-description-slider');
        const descriptionSlider = sliders[descriptionSliderElem.id];
        const featuresSliderElem = findElem('.product-page__materials-features-slider');
        const featuresSlider = sliders[featuresSliderElem.id];

        descriptionSlider.goTo(navCurrentIndex);
        featuresSlider.goTo(navCurrentIndex);
      });
    },
  };
}
