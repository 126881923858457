import { showErrorNotification } from '../components/notifications';
import { findAllElems, findElem } from '../helpers/domHelpers';

export function initFavouritesPage() {
  if (!findElem('.favourites-page')) {
    return;
  }

  initRemoveFromFavourites();

  // initProductFilters({
  //   itemsWrapperSelector: '.favourites-page__items',
  //   filterUrlPrefix: 'favourite_filter',
  // });
}

function initRemoveFromFavourites() {
  findAllElems('.favourites-page__remove-item-btn').forEach((btnElem) => {
    btnElem.addEventListener('click', (event) => {
      event.preventDefault();

      (async () => {
        const btnTextElem = findElem('span', btnElem);
        const productId = btnElem.getAttribute('data-product-id');
        btnElem.setAttribute('disabled', '');

        const likesBtnCountElem = findElem('.likes-btn__count');
        const likesBtnCount = parseInt(likesBtnCountElem.textContent, 10);
        likesBtnCountElem.textContent = `${likesBtnCount - 1}`;

        let buttonTextTemplateElem = findElem(
          btnElem.classList.contains('unfavourited')
            ? '#removeFromFavouritesButtonText'
            : '#addToFavouritesButtonText',
        );

        let requestUrl;
        let method;
        const formData = new FormData();
        if (btnElem.classList.contains('unfavourited')) {
          requestUrl = `/favorites`;
          method = 'POST';
          formData.append('id', productId);
        } else {
          requestUrl = `/favorites/${productId}`;
          method = 'DELETE';
        }

        btnElem.classList.toggle('unfavourited');
        // eslint-disable-next-line no-param-reassign
        btnTextElem.textContent = buttonTextTemplateElem.content.textContent;

        fetch(requestUrl, { method, body: formData }).then((response) => {
          if (!response.ok) {
            btnElem.classList.toggle('unfavourited');
            likesBtnCountElem.textContent = `${likesBtnCount}`;

            buttonTextTemplateElem = findElem(
              btnElem.classList.contains('unfavourited')
                ? '#addToFavouritesButtonText'
                : '#removeFromFavouritesButtonText',
            );

            // eslint-disable-next-line no-param-reassign
            btnTextElem.textContent = buttonTextTemplateElem.content.textContent;

            const errorMessageTemplateElem = findElem(
              btnElem.classList.contains('unfavourited')
                ? '#addToFavouritesErrorMessage'
                : '#removeFromFavouritesErrorMessage',
            );

            if (errorMessageTemplateElem) {
              showErrorNotification(errorMessageTemplateElem.content.textContent);
            }
          }

          btnElem.removeAttribute('disabled');
        });
      })();
    });
  });
}
