import { showErrorNotification } from '../components/notifications';
import { findAllElems, findElem } from '../helpers/domHelpers';

export function initMoodboardPage() {
  if (!findElem('.moodboard-page')) {
    return;
  }

  initRemoveFromMoodboard();
}

function initRemoveFromMoodboard() {
  findAllElems('.moodboard-page__remove-item-btn').forEach((btnElem) => {
    btnElem.addEventListener('click', () => {
      (async () => {
        const productId = btnElem.getAttribute('data-product-id');
        const moodboardId = btnElem.getAttribute('data-moodboard-id');
        const btnTextElem = findElem('span', btnElem);
        btnElem.setAttribute('disabled', '');

        let buttonTextTemplateElem = findElem(
          btnElem.classList.contains('removed')
            ? '#removeFromMoodboardButtonText'
            : '#addToMoodboardButtonText',
        );

        let requestUrl;
        let method;
        const formData = new FormData();
        if (btnElem.classList.contains('removed')) {
          requestUrl = `/b2c_api/v1/add_product_to_moodboard`;
          method = 'POST';
          formData.append('moodboard_id', moodboardId);
          formData.append('product_id', productId);
        } else {
          requestUrl = `/b2c_api/v1/remove_product_from_moodboard/${moodboardId}/${productId}`;
          method = 'DELETE';
        }

        btnElem.classList.toggle('removed');
        // eslint-disable-next-line no-param-reassign
        btnTextElem.textContent = buttonTextTemplateElem.content.textContent;

        fetch(requestUrl, { method, body: formData }).then((response) => {
          if (!response.ok) {
            btnElem.classList.toggle('removed');

            buttonTextTemplateElem = findElem(
              btnElem.classList.contains('removed')
                ? '#addToMoodboardButtonText'
                : '#removeFromMoodboardButtonText',
            );

            // eslint-disable-next-line no-param-reassign
            btnTextElem.textContent = buttonTextTemplateElem.content.textContent;

            const errorMessageTemplateElem = findElem(
              btnElem.classList.contains('removed')
                ? '#addToMoodboardErrorMessage'
                : '#removeFromMoodboardErrorMessage',
            );

            if (errorMessageTemplateElem) {
              showErrorNotification(errorMessageTemplateElem.content.textContent);
            }
          }

          btnElem.removeAttribute('disabled');
        });
      })();
    });
  });
}
