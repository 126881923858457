import { findAllElems, findElem } from '../helpers/domHelpers';

export function initAccordions() {
  findAllElems('[data-accordion-target]').forEach((toggleElem) => {
    const targetElem = findElem(toggleElem.getAttribute('data-accordion-target'));

    toggleElem.addEventListener('click', () => {
      if (targetElem.classList.contains('active')) {
        targetElem.style.maxHeight = '0px';
      } else {
        const height = targetElem.scrollHeight;
        targetElem.style.maxHeight = `${height}px`;
      }

      toggleElem.classList.toggle('active');
      targetElem.classList.toggle('active');
    });
  });
}
