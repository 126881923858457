import {
  disableSelectFormField,
  enableSelectFormField,
  reInitSelectOptions,
  toggleSelectFormFieldLoading,
} from '../components/form/select';
import { showErrorNotification } from '../components/notifications';
import { findElem } from '../helpers/domHelpers';
import { parseJSONResponse } from '../helpers/parseResponse';
import { waitPromise } from '../helpers/waitPromise';

export function initProfilePage() {
  if (!findElem('.profile-page')) {
    return;
  }

  initProfileFormSwitchers();
  initProfileStateFieldOptionsUpdate();
}

function initProfileFormSwitchers() {
  const container = findElem('.profile');
  const sectionTitle = findElem('.section-title');
  const changePasswordContainer = findElem('.change-password');

  const changePasswordFormSwitchElem = findElem('.profile__change-password-form-switch');
  changePasswordFormSwitchElem.addEventListener('click', () => {
    container.classList.add('hidden');
    changePasswordContainer.classList.remove('hidden');
    sectionTitle.textContent = changePasswordFormSwitchElem.getAttribute('data-section-title');
  });

  const profileFormSwitchElem = findElem('.change-password__back-to-profile-banner');
  profileFormSwitchElem.addEventListener('click', () => {
    changePasswordContainer.classList.add('hidden');
    container.classList.remove('hidden');
    sectionTitle.textContent = profileFormSwitchElem.getAttribute('data-section-title');
  });
}

export function initProfileStateFieldOptionsUpdate() {
  const countrySelectElem = findElem('[name="user[user_profile_attributes][country_id]"]');
  const stateSelectElem = findElem('[name="user[user_profile_attributes][state_id]"]');
  countrySelectElem.addEventListener('change', async () => {
    disableSelectFormField(stateSelectElem);
    toggleSelectFormFieldLoading(stateSelectElem, true);

    const { response, error } = await waitPromise(
      fetch(`/api/v2/storefront/countries/${countrySelectElem.value}?include=states`),
    );

    if (error) {
      showErrorNotification('Error occurred while loading states');
      return;
    }

    const json = await response.json();
    const country = parseJSONResponse(json);
    const states = country.states.map((state) => ({
      id: state.id,
      label: state.name,
    }));

    reInitSelectOptions(stateSelectElem, states);

    toggleSelectFormFieldLoading(stateSelectElem, false);
    enableSelectFormField(stateSelectElem);
  });
}
