import { findAllElems, isTouchDevice } from '../helpers/domHelpers';

export function initLangSwitch() {
  findAllElems('.lang-switch').forEach((wrapperElem) => {
    if (isTouchDevice) {
      const closeOnClickOutside = (event) => {
        if (!wrapperElem.contains(event.target)) {
          wrapperElem.classList.remove('lang-switch--mobile-active');
          document.removeEventListener('click', closeOnClickOutside);
        }
      };

      wrapperElem.addEventListener('click', () => {
        wrapperElem.classList.add('lang-switch--mobile-active');

        document.addEventListener('click', closeOnClickOutside);
      });
    }

    findAllElems('a', wrapperElem).forEach((linkElem) => {
      linkElem.addEventListener('click', (event) => {
        if (isTouchDevice && !wrapperElem.classList.contains('lang-switch--mobile-active')) {
          event.preventDefault();
        }
      });
    });
  });
}
