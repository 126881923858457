import { ESCAPE_KEY_CODE, FOCUSABLE_SELECTOR } from '../helpers/constants';
import { findAllElems, findElem } from '../helpers/domHelpers';
import { getTabKeyHandler } from '../helpers/getTabKeyHandler';

export function initMoodboardsPage() {
  if (!findElem('.moodboards-page')) {
    return;
  }

  initMoodboardActions();
  initMoodboardForm();
}

function initMoodboardActions() {
  findAllElems('.moodboard__actions').forEach((actionElem) => {
    const toggleElem = findElem('.moodboard__actions-toggle', actionElem);
    const listElem = toggleElem.nextElementSibling;
    const focusableElems = findAllElems(FOCUSABLE_SELECTOR, listElem);
    let lastFocusableElem = null;

    toggleElem.addEventListener('click', () => {
      lastFocusableElem = document.activeElement;
      listElem.classList.toggle('active');

      if (listElem.classList.contains('active')) {
        document.addEventListener('click', onClickOutsideHide);
        document.addEventListener('keyup', onKeyupEscapeupHide);
        document.addEventListener('keydown', onKeyDownTabHandler);

        if (focusableElems.length) {
          focusableElems[0].focus();
        }
      } else {
        hide();
      }
    });

    findAllElems('.moodboard__actions-list button').forEach((actionListElem) => {
      actionListElem.addEventListener('click', () => {
        listElem.classList.remove('active');
        document.removeEventListener('click', onClickOutsideHide);
      });
    });

    function hide() {
      listElem.classList.remove('active');
      document.removeEventListener('click', onClickOutsideHide);
      document.removeEventListener('keyup', onKeyupEscapeupHide);
      document.removeEventListener('keydown', onKeyDownTabHandler);

      if (lastFocusableElem) {
        lastFocusableElem.focus();
      }
    }

    function onClickOutsideHide(event) {
      if (!actionElem.contains(event.target)) {
        hide();
      }
    }

    function onKeyupEscapeupHide(event) {
      if (event.keyCode === ESCAPE_KEY_CODE) {
        hide();
      }
    }

    function onKeyDownTabHandler() {
      return getTabKeyHandler(focusableElems);
    }
  });
}

function initMoodboardForm() {
  const createMoodboardModalToggle = findElem('.moodboards__add-btn-wrapper button');
  const createMoodboardModal = findElem('#createMoodboardFormModal');
  const createModalInputName = findElem('[name="name"]', createMoodboardModal);

  const editModal = findElem('#editMoodboardFormModal');
  const editModalForm = findElem('form', editModal);
  const editModalInputName = findElem('[name="name"]', editModal);

  const deleteModal = findElem('#deleteMoodboardFormModal');
  const deleteModalForm = findElem('form', deleteModal);

  createMoodboardModalToggle.addEventListener('click', () => {
    createModalInputName.value = '';
    findAllElems('.form-field--touched, .form-field--error', createMoodboardModal).forEach(
      (formFieldElem) => {
        formFieldElem.classList.remove('form-field--touched');
        formFieldElem.classList.remove('form-field--error');
      },
    );
  });

  findAllElems('.moodboard__edit-btn').forEach((editBtnElem) => {
    editBtnElem.addEventListener('click', () => {
      editModalForm.setAttribute(
        'action',
        `/moodboards/${editBtnElem.getAttribute('data-modal-moodboard-id')}`,
      );
      editModalInputName.value = editBtnElem.getAttribute('data-modal-moodboard-name');
      editModalInputName.dispatchEvent(new Event('change'));
    });
  });

  let currentMoodboardIdToDelete = null;
  findAllElems('.moodboard__delete-btn').forEach((deleteBtnElem) => {
    const moodboardNameElem = document.getElementById('deleteMoodboardModalMoodboardName');
    deleteBtnElem.addEventListener('click', () => {
      currentMoodboardIdToDelete = deleteBtnElem.getAttribute('data-modal-moodboard-id');
      deleteModalForm.setAttribute('action', `/moodboards/${currentMoodboardIdToDelete}`);
      moodboardNameElem.textContent = deleteBtnElem.getAttribute('data-modal-moodboard-name');
    });
  });

  deleteModalForm.addEventListener('submitsuccess', () => {
    findElem('.modal__close-btn', deleteModal).click();
    findElem(`[data-modal-moodboard-id="${currentMoodboardIdToDelete}"]`)
      .closest('.moodboard')
      .remove();
  });
}
