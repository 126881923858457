export function waitPromise(promise) {
  return new Promise((resolve) => {
    promise
      .then((response) => {
        if (response.ok) {
          resolve({ response });
        } else {
          resolve({ error: response });
        }
      })
      .catch((error) => {
        resolve({ error });
      });
  });
}
