import { findAllElems, findElem } from '../helpers/domHelpers';

export function initCatalogsPage() {
  if (!findElem('.catalogs-page')) {
    return;
  }

  prepareCatalogsSliders();
}

function prepareCatalogsSliders() {
  findAllElems('.catalogs-page__slider').forEach((sliderElem) => {
    // eslint-disable-next-line no-param-reassign
    sliderElem._sliderProps = {
      gutter: 10,
      center: false,
      loop: false,
      responsive: {
        993: {
          items: 3,
        },
        769: {
          items: 2,
        },
      },
      onInit: (slider) => {
        const { slideCount } = slider.getInfo();

        if (slideCount > 2) {
          window.slider = slider;
        }
      },
    };
  });
}
