import { findElem } from '../../helpers/domHelpers';

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function getValidationRequired(inputElem, inputElemType) {
  return {
    func: () => {
      if (inputElemType === 'checkbox') {
        return inputElem.checked;
      }
      return !!inputElem.value.trim();
    },
    errorMessage: window.FORM_ERRORS.cannotBeEmpty,
  };
}

export function getValidationEmail(inputElem) {
  return {
    func: () => !inputElem.value.trim() || emailRegex.test(String(inputElem.value).toLowerCase()),
    errorMessage: window.FORM_ERRORS.invalidEmail,
  };
}

export function getValidationRepeatPassword(inputElem, formElem) {
  const repeatPasswordFieldName = inputElem.getAttribute('validation-repeat-password');
  const repeatPasswordFieldElem = findElem(`[name="${repeatPasswordFieldName}"]`, formElem);

  return {
    func: () => !inputElem.value.trim() || inputElem.value === repeatPasswordFieldElem.value,
    errorMessage: window.FORM_ERRORS.passwordsMustMatch,
  };
}

export function getValidationMinlen(inputElem) {
  const minlen = parseInt(inputElem.getAttribute('validation-minlen'), 10);

  if (Number.isNaN(minlen)) {
    console.error('Invalid value for validation of min length');
  }
  return {
    func: () => inputElem.value.trim() && inputElem.value.trim().length >= minlen,
    errorMessage: window.FORM_ERRORS.minLength(minlen),
  };
}

export function getValidationMaxlen(inputElem) {
  const maxlen = parseInt(inputElem.getAttribute('validation-maxlen'), 10);

  if (Number.isNaN(maxlen)) {
    console.error('Invalid value for validation of max length');
  }

  return {
    func: () => !inputElem.value.trim() || inputElem.value.trim().length <= maxlen,
    errorMessage: window.FORM_ERRORS.maxLength(maxlen),
  };
}

export function getValidationUrl(inputElem) {
  return {
    func: () => {
      const value = inputElem.value.trim();

      if (!value) {
        return true;
      }

      try {
        new URL(value);
      } catch (e) {
        return false;
      }

      return true;
    },
    errorMessage: window.FORM_ERRORS.invalidURL,
  };
}

export function validateRepeatPassword(inputElem, formElem, formFieldValidators) {
  const repeatPasswordFieldName = inputElem.getAttribute('validation-repeat-password');
  const repeatPasswordFieldElem = findElem(`[name="${repeatPasswordFieldName}"]`, formElem);
  const formFieldValidator = formFieldValidators.find(
    (validator) => validator.inputElem === repeatPasswordFieldElem,
  );
  formFieldValidator.validate(false);
}
