import { InfiniteScroll } from '../components/infinite-scroll';
import { findElem } from '../helpers/domHelpers';

export function initArtistsPage() {
  if (!findElem('.artists-page')) {
    return;
  }

  new InfiniteScroll('.artists-page__grid', `/b2c_api/v1${window.location.pathname}`);
}
