import { tns } from 'tiny-slider/src/tiny-slider';
import { findAllElems, findElem } from '../helpers/domHelpers';

export const sliders = {};

export function initSliders() {
  findAllElems('.slider').forEach(initSlider);
}

export function initSlider(root) {
  if (!root.id) {
    // eslint-disable-next-line no-param-reassign
    root.id = `slider-${`${Math.random()}`.slice(2)}`;
  }

  const currentSlideLine = findElem('.slider__current-slide-line', root);
  const arrowLeft = findElem('.slider__arrow--left', root);
  const arrowRight = findElem('.slider__arrow--right', root);

  const { onInit, itemsForCalculation, ...extraProps } = root._sliderProps || {};
  const slider = tns({
    container: `#${root.id} .slider__slides`,
    mouseDrag: true,
    center: true,
    items: 1,
    controls: false,
    preventActionWhenRunning: true,
    onInit: ({ slideCount, items }) => {
      root.classList.add(`slider--num-of-slides-${slideCount}`);
      innerOnInit(itemsForCalculation || items, slideCount);

      const realSlideCount = getSlideCountForLines(itemsForCalculation || items, slideCount);

      if (arrowLeft) {
        arrowLeft.addEventListener('click', () => {
          sliders[root.id].goTo('prev');
        });

        if (realSlideCount === 1 || extraProps.loop === false) {
          arrowLeft.setAttribute('disabled', '');
        }
      }

      if (arrowRight) {
        arrowRight.addEventListener('click', () => {
          sliders[root.id].goTo('next');
        });

        if (realSlideCount === 1) {
          arrowRight.setAttribute('disabled', '');
        }
      }
    },
    ...extraProps,
  });
  sliders[root.id] = slider;

  enhanceRebuild();
  handleBreakpointChange();
  initSlideLinesNavigation();

  if (onInit) {
    onInit(slider);
  }

  function innerOnInit(items, slideCount) {
    const slidesLines = findElem('.slider__slides-lines', root);

    if (!slidesLines) {
      return;
    }

    const realSlideCount = getSlideCountForLines(items, slideCount);

    findAllElems('.slider__slide-line', slidesLines).forEach((slideLineElem) =>
      slideLineElem.remove(),
    );
    for (let i = 0; i < realSlideCount; i += 1) {
      const slideLine = document.createElement('div');
      slideLine.classList.add('slider__slide-line');

      slideLine.addEventListener('click', () => {
        sliders[root.id].goTo(i);
      });
      slidesLines.appendChild(slideLine);
    }

    currentSlideLine.style.width = `${100 / realSlideCount}%`;
  }

  function checkArrows(loop, items, slideCount, displayIndex) {
    if (loop === false && getSlideCountForLines(items, slideCount) === displayIndex) {
      arrowRight.setAttribute('disabled', '');
    } else if (loop === false) {
      arrowRight.removeAttribute('disabled');
    }

    if (loop === false && displayIndex === 1) {
      arrowLeft.setAttribute('disabled', '');
    } else if (loop === false) {
      arrowLeft.removeAttribute('disabled');
    }
  }

  function initSlideLinesNavigation() {
    if (currentSlideLine && sliders[root.id]) {
      sliders[root.id].events.on('transitionStart', ({ displayIndex, slideCount, items }) => {
        const realSlideCount = getSlideCountForLines(itemsForCalculation || items, slideCount);
        const realDisplayIndex =
          displayIndex > realSlideCount ? displayIndex - realSlideCount : displayIndex;
        const translateIndex = realDisplayIndex <= realSlideCount ? realDisplayIndex : 1;
        currentSlideLine.style.transform = `translateX(${100 * (translateIndex - 1)}%)`;

        const { loop } = extraProps;

        checkArrows(loop, itemsForCalculation || items, slideCount, realDisplayIndex);
      });
    }
  }

  function enhanceRebuild() {
    if (!sliders[root.id]) {
      return;
    }
    const originalRebuild = sliders[root.id].rebuild.bind(sliders[root.id]);
    sliders[root.id].rebuild = () => {
      sliders[root.id] = originalRebuild();

      initSlideLinesNavigation();
      handleBreakpointChange();
      enhanceRebuild();

      return sliders[root.id];
    };
  }

  function handleBreakpointChange() {
    if (!sliders[root.id]) {
      return;
    }
    sliders[root.id].events.on('newBreakpointEnd', () => {
      const sliderInfo = sliders[root.id].getInfo();
      const slideCount =
        (itemsForCalculation || sliderInfo.items) === sliderInfo.slideCount
          ? 1
          : sliderInfo.slideCount;

      innerOnInit(itemsForCalculation || sliderInfo.items, slideCount);

      sliders[root.id].refresh();

      if (currentSlideLine) {
        const translateIndex = sliderInfo.displayIndex <= slideCount ? sliderInfo.displayIndex : 1;
        currentSlideLine.style.transform = `translateX(${100 * (translateIndex - 1)}%)`;

        const { loop } = extraProps;

        checkArrows(
          loop,
          itemsForCalculation || sliderInfo.items,
          sliderInfo.slideCount,
          sliderInfo.displayIndex,
        );
      }
    });
  }
}

function getSlideCountForLines(items, slideCount) {
  return items === slideCount ? 1 : slideCount - items + 1;
}
