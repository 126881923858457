import { findElem } from '../helpers/domHelpers';

export function initNavigationAndTopBarSticky() {
  const nav = findElem('.nav');
  const topBar = findElem('.top-bar');

  new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.target === nav) {
        nav.classList.toggle('nav--sticky', !entry.isIntersecting);

        if (entry.isIntersecting) {
          topBar.classList.remove('top-bar--sticky-visible');
          nav.classList.remove('nav--top-bar-sticky');
        }
      }
    });
  }, {}).observe(nav);

  const navSearch = findElem('.nav__search');
  const navInput = findElem('input', navSearch);

  if (!nav.classList.contains('nav--search-active')) {
    navSearch.addEventListener('click', () => {
      navInput.focus();
    });

    navInput.addEventListener('focus', () => {
      nav.classList.add('nav--search-active');
    });

    navInput.addEventListener('blur', () => {
      nav.classList.remove('nav--search-active');
    });
  }

  findElem('#navSearchIcon', navSearch).addEventListener('mousedown', (event) => {
    if (nav.classList.contains('nav--search-active')) {
      event.preventDefault();
      navSearch.submit();
    }
  });
  findElem('#navSearchClear', navSearch).addEventListener('click', () => {
    navInput.value = '';
  });
  findElem('#navSearchClear', navSearch).addEventListener('mousedown', (event) => {
    event.preventDefault();
  });

  let lastScrollTop = 0;
  window.addEventListener(
    'scroll',
    () => {
      if (!nav.classList.contains('nav--sticky')) {
        return;
      }

      const st = window.pageYOffset || document.documentElement.scrollTop;
      if (st < lastScrollTop) {
        topBar.classList.add('top-bar--sticky-visible');
        nav.classList.add('nav--top-bar-sticky');
      } else if (st > lastScrollTop) {
        topBar.classList.remove('top-bar--sticky-visible');
        nav.classList.remove('nav--top-bar-sticky');
      }
      lastScrollTop = st <= 0 ? 0 : st;
    },
    false,
  );
}
