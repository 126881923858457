import { ESCAPE_KEY_CODE, FOCUSABLE_SELECTOR } from '../helpers/constants';
import { findElem, findAllElems } from '../helpers/domHelpers';
import { getTabKeyHandler } from '../helpers/getTabKeyHandler';

export function initModals() {
  findAllElems('[data-modal-target]').forEach((modalToggleElem) => {
    if (modalToggleElem._modalInited) {
      return;
    }
    // eslint-disable-next-line no-param-reassign
    modalToggleElem._modalInited = true;
    const modalElem = findElem(modalToggleElem.getAttribute('data-modal-target'));
    const modalContentElem = findElem('.modal__content', modalElem);
    const focusableElems = findAllElems(FOCUSABLE_SELECTOR, modalContentElem);
    let lastFocusableElem = null;

    modalToggleElem.addEventListener('click', () => {
      lastFocusableElem = document.activeElement;
      modalElem.classList.add('active');
      modalElem.addEventListener('click', onClickOutsideCloseModal);
      document.addEventListener('keyup', onKeyupEscapeCloseModal);
      document.addEventListener('keydown', onKeyDownTabHandler);
      document.body.classList.add('overflow-hidden');

      if (focusableElems.length) {
        const firstElementToFocus =
          focusableElems.find((elem) => elem.hasAttribute('data-modal-auto-focus')) ||
          focusableElems[0];
        firstElementToFocus.focus();
      }
    });

    findAllElems('[data-modal-close-trigger]', modalElem).forEach((closeTriggerElem) => {
      closeTriggerElem.addEventListener('click', closeModal);
    });

    function onKeyDownTabHandler() {
      return getTabKeyHandler(focusableElems);
    }

    function closeModal() {
      modalElem.classList.remove('active');
      modalElem.removeEventListener('click', onClickOutsideCloseModal);
      document.removeEventListener('keyup', onKeyupEscapeCloseModal);
      document.removeEventListener('keydown', onKeyDownTabHandler);
      document.body.classList.remove('overflow-hidden');

      if (lastFocusableElem) {
        lastFocusableElem.focus();
      }
    }

    function onClickOutsideCloseModal(event) {
      if (!modalContentElem.contains(event.target)) {
        closeModal();
      }
    }

    function onKeyupEscapeCloseModal(event) {
      if (event.keyCode === ESCAPE_KEY_CODE) {
        closeModal();
      }
    }
  });
}
