import { findElem } from '../helpers/domHelpers';

export function initAcceptCookies() {
  const cookiesAcceptedValue = getCookie();

  if (cookiesAcceptedValue !== 'true') {
    const wrapper = findElem('.accept-cookies');
    wrapper.classList.add('accept-cookies--active');

    findElem('.accept-cookies__accept-btn').addEventListener('click', () => {
      saveCookie();
      wrapper.classList.remove('accept-cookies--active');
    });

    findElem('.accept-cookies__close-btn').addEventListener('click', () => {
      wrapper.classList.remove('accept-cookies--active');
    });
  }
}

const cookieName = 'cookies_accepted';
const cookieLiveTime = 365 * 24 * 60 * 60 * 1000;

function saveCookie() {
  const date = new Date();
  date.setTime(date.getTime() + cookieLiveTime);
  document.cookie = `${cookieName}=true; expires=${date.toUTCString()} path=/`;
}

function getCookie() {
  const cookiesAccepted = decodeURIComponent(document.cookie)
    .split(';')
    .find((cookie) => {
      const startIndex = cookie.charAt(0) === ' ' ? 1 : 0;

      return cookie.indexOf(`${cookieName}=`) === startIndex;
    });

  if (!cookiesAccepted) {
    return null;
  }

  const startIndex = cookiesAccepted.charAt(0) === ' ' ? 1 : 0;

  return cookiesAccepted.substring(`${cookieName}=`.length + startIndex);
}
