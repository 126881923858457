import { findAllElems, findElem } from '../helpers/domHelpers';
import { waitPromise } from '../helpers/waitPromise';
import { centerMapOnShowroom } from '../components/showrooms';

function initToggleElem(toggleElem) {
  toggleElem.addEventListener('click', () => {
    const showroomId = toggleElem.getAttribute('data-showroom-id');
    centerMapOnShowroom(showroomId);
  });
}

export function initShowroomMapToggles() {
  findAllElems('.contact-us__showroom-map-toggle').forEach(initToggleElem);
}

export async function initLoadMoreShowroomsButton() {
  const loadMoreShowroomsBtn = findElem('#loadMoreShowroomsBtn');

  loadMoreShowroomsBtn.addEventListener('click', async () => {
    loadMoreShowroomsBtn.setAttribute('disabled', '');
    loadMoreShowroomsBtn.classList.add('loading');

    let currentNumberOfShowrooms = findAllElems('.contact-us__showroom').length;
    const { response, error } = await waitPromise(
      fetch(`/showrooms?cursor=${currentNumberOfShowrooms}`),
    );

    if (error) {
      loadMoreShowroomsBtn.removeAttribute('disabled', '');
      loadMoreShowroomsBtn.classList.remove('loading');
      // show error notification
      return;
    }

    const { data, totalCount } = await response.json();
    const wrapper = document.createElement('div');
    wrapper.innerHTML = data;
    const showroomsElem = findElem('.contact-us__showrooms');
    findAllElems('.contact-us__showroom', wrapper).forEach((showroomElem) => {
      const toggleElem = findElem('.contact-us__showroom-map-toggle', showroomElem);
      initToggleElem(toggleElem);
      showroomsElem.appendChild(showroomElem);
    });

    currentNumberOfShowrooms = findAllElems('.contact-us__showroom').length;
    if (currentNumberOfShowrooms === totalCount) {
      loadMoreShowroomsBtn.remove();
    }

    loadMoreShowroomsBtn.removeAttribute('disabled', '');
    loadMoreShowroomsBtn.classList.remove('loading');
  });
}
