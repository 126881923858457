import { findAllElems, findElem, scrollToElement } from '../helpers/domHelpers';
import margerImage from '../images/marker.svg';

let mapElement = null;
let map = null;
export const showroomMarkers = [];

export function showroomsMap() {
  mapElement = findElem('#showroomsMap');

  if (!mapElement) {
    return;
  }

  const { showrooms } = window;
  const mainShowroom = showrooms.find(({ main }) => main) || showrooms[0];
  const showroomInfoWindowTemplateElem = findElem('#showroomInfoWindowTemplate').content;

  function initMap() {
    const { google } = window;
    map = new google.maps.Map(mapElement, {
      center: { lat: mainShowroom.lat, lng: mainShowroom.lng },
      zoom: 7,
      disableDefaultUI: true,
      styles: mapStyles,
    });

    showrooms.forEach((showroom) => {
      const marker = new google.maps.Marker({
        position: { lat: showroom.lat, lng: showroom.lng },
        map,
        icon: {
          anchor: new google.maps.Point(30, 30),
          url: `${margerImage}#${showroom.id}`,
        },
      });

      const showroomInfoWindowElem = showroomInfoWindowTemplateElem.cloneNode(true);

      if (showroom.img) {
        findElem('img', showroomInfoWindowElem).setAttribute('src', showroom.img);
      }
      findElem('.showrooms__info-window-title', showroomInfoWindowElem).textContent =
        showroom.title;
      findElem('.showrooms__info-window-address', showroomInfoWindowElem).textContent =
        showroom.address;

      const wrapperElem = document.createElement('div');
      wrapperElem.appendChild(showroomInfoWindowElem);
      const infoWindow = new google.maps.InfoWindow({
        content: wrapperElem.innerHTML,
      });

      google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
        findAllElems('.showrooms__info-window-close-btn').forEach((closeBtn) => {
          if (closeBtn.hasAttribute('data-listener-attached')) {
            return;
          }

          closeBtn.setAttribute('data-listener-attached', true);
          closeBtn.addEventListener('click', () => {
            /**
             * There is a known bug of page jump after info window
             * is closed, so to prevent this we disable scroll
             * before close and enable after with some delay
             */
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            window.onscroll = () => window.scrollTo(0, scrollTop);

            infoWindow.close();

            setTimeout(() => {
              window.onscroll = null;
            }, 100);
          });
        });
      });

      google.maps.event.addListener(marker, 'click', () => {
        infoWindow.open(map, marker);
      });

      google.maps.event.addListener(map, 'click', () => {
        infoWindow.close();
      });

      showroomMarkers.push({
        showroomId: showroom.id,
        marker,
        infoWindow,
        map,
      });
    });
  }

  window.initMap = initMap;

  const script = document.createElement('script');
  script.src =
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyBhUXXuAqljh_auhxNsYbYuIF4yX-6mzYs&callback=initMap';
  script.async = true;
  document.head.appendChild(script);
}

export async function centerMapOnShowroom(showroomId) {
  if (!map) {
    return;
  }

  const { google, showrooms } = window;

  const showroom = showrooms.find(({ id }) => showroomId === id);

  if (!showroom) {
    return;
  }

  showroomMarkers.forEach(({ infoWindow }) => {
    infoWindow.close();
  });

  const { marker, infoWindow } = showroomMarkers.find(({ showroomId: id }) => id === showroomId);

  await scrollToElement(mapElement, 300);

  map.panTo(new google.maps.LatLng(showroom.lat, showroom.lng));
  infoWindow.open(map, marker);
}

const mapStyles = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#212121',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#212121',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#181818',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1b1b1b',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#2c2c2c',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#8a8a8a',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#373737',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#3c3c3c',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry',
    stylers: [
      {
        color: '#4e4e4e',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#3d3d3d',
      },
    ],
  },
];
