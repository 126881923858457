// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

// Rails.start()
// Turbolinks.start()
// ActiveStorage.start()

import { initSliders } from './loveyourwall/components/slider';
import {
  initMenu,
  prepareSliderPropsForMenuCollectionsSlider,
} from './loveyourwall/components/menu';
import { initNavigationAndTopBarSticky } from './loveyourwall/components/navigation-and-top-bar';
import { initFormFields } from './loveyourwall/components/form/index';
import { initModals } from './loveyourwall/components/modal';
import { initAcceptCookies } from './loveyourwall/components/accept-cookies';
import { initLangSwitch } from './loveyourwall/components/lang-switch';
import { initLightBox } from './loveyourwall/components/light-box';
import { initAccordions } from './loveyourwall/components/accordion';
import { showroomsMap } from './loveyourwall/components/showrooms';
import {
  handleGeneralServerSideNotifications,
  handleFormServerSideErrors,
} from './loveyourwall/components/server-side-notifications';

import { initLoginRegisterPage } from './loveyourwall/pages/login-register';
import { initProfilePage } from './loveyourwall/pages/profile';
import { initShowroomMapToggles } from './loveyourwall/pages/contact';
import { initProductPage } from './loveyourwall/pages/product';
import { initCollectionPage } from './loveyourwall/pages/collection';
import { initArtistsPage } from './loveyourwall/pages/artists';
import { initHomePage } from './loveyourwall/pages/home';
import { initMoodboardsPage } from './loveyourwall/pages/moodboards';
import { initCatalogsPage } from './loveyourwall/pages/catalogs';
import { initMoodboardPage } from './loveyourwall/pages/moodboard';
import { initFavouritesPage } from './loveyourwall/pages/favourites';
import { initSearchPage } from './loveyourwall/pages/search';

initCollectionPage();
initProductPage();
initMoodboardsPage();
initArtistsPage();
initHomePage();
initLoginRegisterPage();
initProfilePage();
initCatalogsPage();
initMoodboardPage();
initFavouritesPage();
initSearchPage();

prepareSliderPropsForMenuCollectionsSlider();

initNavigationAndTopBarSticky();
initSliders();
initLightBox();
initAccordions();
initMenu();
handleFormServerSideErrors();
initFormFields();
initModals();
initLangSwitch();
initShowroomMapToggles();
handleGeneralServerSideNotifications();

showroomsMap();

setTimeout(() => {
  initAcceptCookies();
}, 3000);
