import { findElem, findAllElems } from '../helpers/domHelpers';

const menu = findElem('.menu');
const menuTriggers = findAllElems('.menu__trigger');

export function initMenu() {
  menuTriggers.forEach((menuTrigger) => {
    menuTrigger.addEventListener('click', () => {
      document.body.classList.toggle('overflow-hidden');
      menu.classList.toggle('active');
    });
  });
}

export function prepareSliderPropsForMenuCollectionsSlider() {
  const sliderElement = findElem('.menu-collections-slider');
  sliderElement._sliderProps = {
    axis: 'vertical',
    items: 3,
    gutter: 40,
    mouseDrag: false,
    touch: false,
    onInit: (slider) => {
      const mediaQuery = window.matchMedia('(max-width: 1200px)');

      findAllElems('.menu__collections-list a', menu).forEach((collectionLink, index) => {
        collectionLink.addEventListener('mouseenter', () => {
          if (!mediaQuery.matches) {
            slider.goTo(index);
          }
        });
      });
    },
  };
}
