import { findElem, findAllElems } from '../helpers/domHelpers';
import { waitPromise } from '../helpers/waitPromise';
import { showErrorNotification } from '../components/notifications';
import { parseJSONResponse } from '../helpers/parseResponse';
import {
  disableSelectFormField,
  enableSelectFormField,
  reInitSelectOptions,
  toggleSelectFormFieldLoading,
} from '../components/form/select';

export function initLoginRegisterPage() {
  if (!findElem('.login-register-page')) {
    return;
  }

  initLoginRegisterFormSwitchers();
  initLoginRegisterStateFieldOptionsUpdate();
}

function initLoginRegisterFormSwitchers() {
  const container = findElem('.login-register');
  const sectionTitle = findElem('.login-register__section-title');

  findAllElems('[data-login-register-target-form]').forEach((switchElem) => {
    switchElem.addEventListener('click', () => {
      const targetForm = switchElem.getAttribute('data-login-register-target-form');

      const currentFormClass = Array.from(container.classList).find((classItem) =>
        classItem.startsWith('login-register--'),
      );
      container.classList.remove(currentFormClass);
      container.classList.add(`login-register--${targetForm}`);
      if (['login', 'reset-password'].includes(targetForm)) {
        sectionTitle.textContent = 'Login';
      } else {
        sectionTitle.textContent = 'Register';
      }
    });
  });

  const loginRegisterForm = findElem('.login-register__form--register');
  const registerForm = findElem('.register');
  if (registerForm && !loginRegisterForm) {
    // Register form, after attemted submission and reload
    const changeElemsSelector = 'input[type="text"], input[type="email"], select';

    findAllElems(changeElemsSelector, registerForm).forEach((elem) => {
      elem.dispatchEvent(new Event('change'));
    });
  }
  if (loginRegisterForm) {
    loginRegisterForm.addEventListener('submit', (event) => {
      loginRegisterForm.validateForm();

      if (!findElem('.form-field--error', loginRegisterForm)) {
        event.preventDefault();

        ['spree_user[email]', 'spree_user[password]', 'spree_user[password_confirmation]'].forEach(
          (fieldName) => {
            const fieldNameSelector = `[name="${fieldName}"]`;
            const fieldInputElem = findElem(fieldNameSelector, registerForm);
            fieldInputElem.value = findElem(fieldNameSelector, loginRegisterForm).value;
            fieldInputElem.dispatchEvent(new Event('change'));
          },
        );
        container.classList.add('hidden');
        registerForm.classList.remove('hidden');
      }
    });
  }

  const backToLoginButton = findElem('.register__back-to-login-banner button');
  if (backToLoginButton) {
    backToLoginButton.addEventListener('click', () => {
      registerForm.classList.add('hidden');
      container.classList.remove('hidden');

      const clickTarget = findElem('[data-login-register-target-form="login"]');
      if (clickTarget) {
        clickTarget.click();
      } else {
        window.location.pathname = '/login';
      }
    });
  }
}

function initLoginRegisterStateFieldOptionsUpdate() {
  const countrySelectElem = findElem('[name="spree_user[user_profile_attributes][country_id]"]');
  const stateSelectElem = findElem('[name="spree_user[user_profile_attributes][state_id]"]');
  countrySelectElem.addEventListener('change', async () => {
    disableSelectFormField(stateSelectElem);
    toggleSelectFormFieldLoading(stateSelectElem, true);

    const { response, error } = await waitPromise(
      fetch(`/api/v2/storefront/countries/${countrySelectElem.value}?include=states`),
    );

    if (error) {
      showErrorNotification('Error occurred while loading states');
      return;
    }

    const json = await response.json();
    const country = parseJSONResponse(json);
    const states = country.states.map((state) => ({
      id: state.id,
      label: state.name,
    }));

    reInitSelectOptions(stateSelectElem, states);

    toggleSelectFormFieldLoading(stateSelectElem, false);
    enableSelectFormField(stateSelectElem);
  });
}
