export function findElem(selector, parent = document) {
  return parent.querySelector(selector);
}

export function findAllElems(selector, parent = document) {
  return Array.from(parent.querySelectorAll(selector));
}

export function removeClassByClassName(className) {
  findAllElems(`.${className}`).forEach((element) => {
    element.classList.remove(className);
  });
}

export const isTouchDevice =
  'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

export function scrollToElement(element, duration) {
  const { top } = element.getBoundingClientRect();
  const { body, documentElement } = document;
  const scrollTop = window.pageYOffset || documentElement.scrollTop || body.scrollTop;
  const clientTop = documentElement.clientTop || body.clientTop || 0;

  const startingY = window.pageYOffset;
  const diff = top + scrollTop - clientTop - startingY;
  let start;

  return new Promise((resolve) => {
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) {
        start = timestamp;
      }
      const time = timestamp - start;
      const percent = Math.min(time / duration, 1);

      window.scrollTo(0, startingY + diff * percent);

      if (time < duration) {
        window.requestAnimationFrame(step);
      } else {
        resolve();
      }
    });
  });
}
