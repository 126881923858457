import { ESCAPE_KEY_CODE, FOCUSABLE_SELECTOR } from '../helpers/constants';
import { findAllElems, findElem } from '../helpers/domHelpers';
import { getTabKeyHandler } from '../helpers/getTabKeyHandler';

export function initLightBox() {
  const lightBoxTemplateElem = findElem('#lightboxTemplate');
  findAllElems('[data-light-box-toggle]').forEach((elem) => {
    if (elem._lightBoxInited) {
      return;
    }
    // eslint-disable-next-line no-param-reassign
    elem._lightBoxInited = true;
    elem.addEventListener('click', (clikEvent) => {
      clikEvent.stopPropagation();
      const lastFocusableElem = document.activeElement;
      const lightBoxElem = findElem('.light-box', lightBoxTemplateElem.content.cloneNode(true));
      const focusableElems = findAllElems(FOCUSABLE_SELECTOR, lightBoxElem);
      const imgElem = findElem('img', lightBoxElem);

      lightBoxElem.addEventListener('click', (event) => {
        if (event.target !== imgElem) {
          closeLightBox();
        }
      });

      imgElem.addEventListener('load', () => {
        lightBoxElem.classList.remove('light-box--loading');
      });

      const imgSrc = elem.getAttribute('data-light-box-toggle');
      imgElem.setAttribute('src', imgSrc);

      document.body.appendChild(lightBoxElem);
      document.addEventListener('keyup', onKeyupEscapeCloseLightBox);
      document.addEventListener('keydown', onKeyDownTabHandler);
      document.body.classList.add('overflow-hidden');

      if (focusableElems.length) {
        focusableElems[0].focus();
      }

      function onKeyupEscapeCloseLightBox(event) {
        if (event.keyCode === ESCAPE_KEY_CODE) {
          closeLightBox();
        }
      }

      function onKeyDownTabHandler() {
        return getTabKeyHandler(focusableElems);
      }

      function closeLightBox() {
        lightBoxElem.addEventListener('animationend', () => {
          lightBoxElem.remove();
        });
        lightBoxElem.classList.add('light-box--inactive');

        document.removeEventListener('keyup', onKeyupEscapeCloseLightBox);
        document.removeEventListener('keydown', onKeyDownTabHandler);
        document.body.classList.remove('overflow-hidden');

        if (lastFocusableElem) {
          lastFocusableElem.focus();
        }
      }
    });
  });
}
